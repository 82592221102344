import React from "react";
import ResultsPage, { colourMap } from "../components/results_page";
import {
  SingleSliderControl,
  SliderControl,
} from "../components/chart_filters";
import country_mapping from "../components/primitives/country_mapping";
import numeral from "numeral";

import { ChartExplorer } from "../components/charts/ChartExplorer";
import { Tagger } from "../components/multi_select";
import Searcher from "../components/searcher";
import categoryColour from "../styles/category_colour";

const tensAndFivesLabels = [
  "< 500",
  "500 - 5k",
  "5k - 10k",
  "10k - 500k",
  "> 500k",
];
const tensAndfives = l =>
  l < 500
    ? tensAndFivesLabels[0]
    : l < 5000
    ? tensAndFivesLabels[1]
    : l < 10000
    ? tensAndFivesLabels[2]
    : l < 500000
    ? tensAndFivesLabels[3]
    : tensAndFivesLabels[4];

const tensAndfivesSort = (a, b) =>
  tensAndFivesLabels.indexOf(a) - tensAndFivesLabels.indexOf(b);

function makeLabelsFromThresholds(thresholds) {
  return thresholds.reduce((acc, threshold, i) => {
    if (i === 0) {
      acc.push("< " + numeral(threshold).format("0a"));
    } else {
      const prevThreshold = thresholds[i - 1];
      acc.push(
        numeral(prevThreshold).format("0a") +
          " - " +
          numeral(threshold).format("0a")
      );
    }
    if (i === thresholds.length - 1) {
      acc.push("> " + numeral(threshold).format("0a"));
    }
    return acc;
  }, []);
}

const total_play_thresholds = [1e4, 5e4, 1e5, 5e5];
const total_play_labels = makeLabelsFromThresholds(total_play_thresholds);

const daily_plays_thresholds = [1000, 2000, 5000, 10000, 500000];
const daily_plays_labels = makeLabelsFromThresholds(daily_plays_thresholds);

function valueToBucket(value, thresholds, labels) {
  for (var i = 0; i < thresholds.length; i++) {
    if (value < thresholds[i]) {
      return labels[i];
    }
  }
  return labels[labels.length - 1];
}

const facetMapping = {
  total_plays: {
    name: "Total Plays",
    func: l => valueToBucket(l, total_play_thresholds, total_play_labels),
    customSort: (a, b) =>
      total_play_labels.indexOf(a) - total_play_labels.indexOf(b),
  },
  plays_yesterday: {
    name: "Plays Yesterday",
    func: l => valueToBucket(l, daily_plays_thresholds, daily_plays_labels),
    customSort: (a, b) =>
      daily_plays_labels.indexOf(a) - daily_plays_labels.indexOf(b),
  },
  avg_daily_last_3_days: {
    name: "Avg. Plays Last 3 Days",
    func: tensAndfives,
    customSort: tensAndfivesSort,
  },
  avg_daily_last_7_days: {
    name: "Avg. Plays Last 7 Days",
    func: tensAndfives,
    customSort: tensAndfivesSort,
  },
  likes: {
    ignored: true,
    name: "Track Likes",
    func: l =>
      l < 100
        ? "< 100"
        : l < 1000
        ? "100 - 999"
        : l < 5000
        ? "1000 - 5000"
        : "> 5000",
  },
  duration: {
    ignored: true,
    func: m => (m < 2 * 60000 ? "< 3 min" : "3 - 7 min"),
  },
  artist_iso2: {
    ignored: true,
    name: "Country",
    func: iso => country_mapping[iso] || "Unknown",
  },
  release_days_ago: {
    name: "Released",
    position: 9,
    func: days =>
      days < 3
        ? "< 2 days ago"
        : days < 10
        ? "3 - 10 days ago"
        : days < 30
        ? "10 - 30 days ago"
        : days < 60
        ? "30 - 60 days ago"
        : "> 60 days ago",
  },
  followers: {
    name: "Artist Followers",
    position: 10,
    func: f => (f < 2000 ? "< 2000" : f < 10000 ? "2k - 10k" : "> 10k"),
  },
};

const countries = Object.entries(country_mapping)
  .map(([code, name]) => ({
    value: code,
    display: name,
  }))
  .filter(x => ["QX", "QZ", "QM", "00"].indexOf(x.value) === -1)
  .sort((c1, c2) => {
    return (
      -1000 * ["DE", "FR", "AU", "CA", "GB", "US"].indexOf(c1.value) -
      -1000 * ["DE", "FR", "AU", "CA", "GB", "US"].indexOf(c2.value) +
      c1.display.localeCompare(c2.display)
    );
  });

// These are like charts, eventually they will merge together.
export const ANALYSER_DEFAULT_FILTERS = {
  timePeriod: 1,
  strength: 0,
  minPlaysYesterday: 100,
  noiseReduction: 0.2,
  country_codes: [],
};

const types = {
  sc: {
    pageTitle: "SoundCloud Analyzer",
    apiEndpoint: "/api/soundcloud_analyzer",
    filters: (currentValues, onModify) => {
      const timePeriod = [0, 1, 2];
      const strength = [0, 15, 50, 200, 800];
      const minPlaysYesterday = [100, 500, 1000, 5000, 10000, 20000];
      const noiseReductionMapping = [0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8];
      return (
        <React.Fragment>
          <div className="filterControl filterTrendTime">
            <Tagger
              label="Country"
              domain={countries}
              initialSelection={currentValues.country_codes || []}
              className="filterControl filter-top200"
              onChange={country_codes => onModify({ country_codes })}
            />
          </div>
          <div className="filterControl filterTrendTime">
            <SingleSliderControl
              title="Time Period"
              tooltipText={
                "The number of days to look for the trend. Shorter periods can spot trends sooner, but may be noisier."
              }
              mapping={timePeriod}
              val={currentValues.timePeriod}
              stateKey="timePeriod"
              onChange={onModify}
              textDisplay={(timePeriod, valid) => {
                return { 0: "Short", 1: "Medium", 2: "Long" }[timePeriod];
              }}
            />
          </div>
          <div className="filterControl filterTrendStrength">
            <SingleSliderControl
              title="Trend Strength"
              tooltipText={"How fast the track appears to be growing."}
              mapping={strength}
              val={currentValues.strength}
              stateKey="strength"
              onChange={onModify}
              textDisplay={(strength, valid) => {
                return {
                  "0": "Any increase",
                  "15": "Minor",
                  "50": "Moderate",
                  "200": "Large",
                  "800": "Very Large",
                }[String(strength)];
              }}
            />
          </div>
          <div className="filterControl filterFollowerCrossings">
            <SingleSliderControl
              title="Minimum Daily Plays"
              tooltipText={
                "Tracks must have at least this number of plays yesterday to appear."
              }
              mapping={minPlaysYesterday}
              val={currentValues.minPlaysYesterday}
              stateKey="minPlaysYesterday"
              onChange={onModify}
              textDisplay={(minPlaysYesterday, valid) => {
                return minPlaysYesterday === 0
                  ? "any"
                  : "At least " +
                      numeral(minPlaysYesterday).format("0a") +
                      " plays";
              }}
            />
          </div>
          <div className="filterControl filterNoiseReduction">
            <SingleSliderControl
              title="Noise Reduction"
              tooltipText={
                "A higher percentage filters for tracks with a clearer upward trend, at the expense of hiding some that might be popping now."
              }
              mapping={noiseReductionMapping}
              val={currentValues.noiseReduction}
              stateKey="noiseReduction"
              onChange={onModify}
              textDisplay={(noiseReduction, valid) => {
                return noiseReduction === 0
                  ? "none"
                  : (noiseReduction * 100).toFixed(0) + " %";
              }}
            />
          </div>
        </React.Fragment>
      );
    },
    columns: [
      "artwork",
      "trackTitle",
      "view_sc_track_details",
      "dailyPlaysSparkline",
      "release_days_ago",
      "plays_yesterday",
      "total_plays",
      "artistName",
    ],
    defaultFilters: ANALYSER_DEFAULT_FILTERS,
    defaultSort: "plays_yesterday",
    bgFrom: colourMap["soundcloud"].primary,
    bgTo: colourMap["soundcloud"].to,
    facetMapping,
  },
  yt: {
    pageTitle: "YouTube Analyzer",
    apiEndpoint: "/api/youtube_analyzer",
    filters: null,
    columns: [
      "artwork",
      "trackTitle",
      "viewYtVideoDetails",
      "dailyPlaysSparkline",
      "release_days_ago",
      "plays_yesterday",
      "total_plays",
      "channel_name",
    ],
    defaultFilters: ANALYSER_DEFAULT_FILTERS,
    defaultSort: "plays_yesterday",
    bgFrom: colourMap["youtube"].primary,
    bgTo: colourMap["youtube"].to,
    facetMapping,
  },
  spy: {
    pageTitle: "Spotify Analyzer",
    apiEndpoint: "/api/spotify_analyzer",
    filters: (currentValues, onModify, revertCount, isModified) => {
      const dayMapping = [0, 1, 2, 5, 10, 30, 60, 120, 1000];
      const popMapping = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
      // prettier-ignore
      const artistFollowerMapping = [0, 10, 20, 30, 50, 100, 500, 1e3, 3e3, 5e3, 1e4, 3e4, 5e4, 1e5, 3e5, 5e5, 1e6, 1e9];
      const playlistFollowerMapping = [0, 1e4, 5e4, 1e6, 5e6, 1e7, 5e7, 1e9];
      return (
        <>
          <div className="filterControl filterReleaseDate">
            <SliderControl
              title="Release Date"
              mapping={dayMapping}
              minVal={currentValues.minPublishedDaysAgo}
              maxVal={currentValues.maxPublishedDaysAgo}
              minValStateKey="minPublishedDaysAgo"
              maxValStateKey="maxPublishedDaysAgo"
              onChange={onModify}
              textDisplay={function textDisplay(minVal, maxVal, mapping) {
                const minAllowedValue = mapping[0];
                const maxAllowedValue = mapping[mapping.length - 1];
                const formattedMin = numeral(minVal).format("0,0");
                const formattedMax = numeral(maxVal).format("0,0");
                return minVal > minAllowedValue && maxVal < maxAllowedValue
                  ? `Between ${formattedMin} and ${formattedMax} days ago`
                  : minVal > minAllowedValue
                  ? `More than ${formattedMin} days ago`
                  : maxVal < maxAllowedValue
                  ? `Less than ${formattedMax} days ago`
                  : "Anytime";
              }}
            />
          </div>
          <div className="filterControl filterFollowers">
            <SliderControl
              title="Spotify Followers"
              mapping={artistFollowerMapping}
              minVal={currentValues.minFollowers}
              maxVal={currentValues.maxFollowers}
              minValStateKey="minFollowers"
              maxValStateKey="maxFollowers"
              onChange={onModify}
            />
          </div>
          <div className="filterControl filterTrackPop">
            <SliderControl
              title="Track Popularity"
              mapping={popMapping}
              minVal={currentValues.minTrackPopularity}
              maxVal={currentValues.maxTrackPopularity}
              minValStateKey="minTrackPopularity"
              maxValStateKey="maxTrackPopularity"
              onChange={onModify}
            />
          </div>
          <div className="filterControl filterArtistPop">
            <SliderControl
              title="Artist Popularity"
              mapping={popMapping}
              minVal={currentValues.minArtistPopularity}
              maxVal={currentValues.maxArtistPopularity}
              minValStateKey="minArtistPopularity"
              maxValStateKey="maxArtistPopularity"
              onChange={onModify}
            />
          </div>
          <div className="filterControl filterTotalPlaylistFollowers">
            <SliderControl
              title="Total Playlist Followers"
              mapping={playlistFollowerMapping}
              minVal={currentValues.minTotalPlaylistFollowers}
              maxVal={currentValues.maxTotalPlaylistFollowers}
              minValStateKey="minTotalPlaylistFollowers"
              maxValStateKey="maxTotalPlaylistFollowers"
              onChange={onModify}
            />
          </div>
          <div className={"filterControl filterSigned"}>
            <label className={currentValues.includeSigned ? "checked" : ""}>
              <input
                type="checkbox"
                checked={currentValues.includeSigned}
                onChange={ev => {
                  onModify({ includeSigned: ev.target.checked });
                }}
              />
              {"Include signed artists"}
            </label>
          </div>
          {/*<Tagger*/}
          {/*  label="Top 200 Charts"*/}
          {/*  key={revertCount + "top200"}*/}
          {/*  domain={allChartCountries.map(code => ({*/}
          {/*    value: code,*/}
          {/*    display:*/}
          {/*      code === "global"*/}
          {/*        ? "Global"*/}
          {/*        : country_mapping[code.toUpperCase()]*/}
          {/*  }))}*/}
          {/*  initialSelection={currentValues.top200CountryCodes || []}*/}
          {/*  className="filterControl filter-top200"*/}
          {/*  onChange={top200CountryCodes =>*/}
          {/*    onModify({top200CountryCodes})*/}
          {/*  }*/}
          {/*/>*/}
          {/*<Tagger*/}
          {/*  label="Playlists"*/}
          {/*  key={revertCount + "playlists"}*/}
          {/*  domain={playlistData.map(pl => ({*/}
          {/*    value: pl.spyid,*/}
          {/*    display: pl.name*/}
          {/*  }))}*/}
          {/*  initialSelection={currentValues.playlistIds || []}*/}
          {/*  className="filterControl filter-playlists"*/}
          {/*  onChange={playlistIds => onModify({playlistIds})}*/}
          {/*/>*/}
          {/*<Tagger*/}
          {/*  label="Genres"*/}
          {/*  key={revertCount + "genres"}*/}
          {/*  domain={allGenres.map(g => ({*/}
          {/*    value: g,*/}
          {/*    display: g*/}
          {/*  }))}*/}
          {/*  initialSelection={currentValues.selectedGenres || []}*/}
          {/*  className="filterControl filter-genres"*/}
          {/*  onChange={selectedGenres => onModify({selectedGenres})}*/}
          {/*/>*/}
        </>
      );
    },
    columns: [
      "artwork",
      "trackTitle",
      "popularitySparkline",
      "release_days_ago",
      "track_popularity",
      "artistName",
    ],
    defaultFilters: {
      includeSigned: false,
      playlistIds: null, // The selector will overwrite null with the user's playlists.
      selectedGenres: [],
      minFollowers: 0,
      maxFollowers: 1e6,
      minPublishedDaysAgo: 0,
      maxPublishedDaysAgo: 30,
      minTotalPlaylistFollowers: 0,
      maxTotalPlaylistFollowers: 1e9,
      minTrackPopularity: 0,
      maxTrackPopularity: 100,
      minArtistPopularity: 0,
      maxArtistPopularity: 100,
      top200CountryCodes: [],
    },
    bgFrom: colourMap["spotify"].primary,
    bgTo: colourMap["spotify"].to,
    facetMapping,
  },
  in: {
    pageTitle: "Instagram Analyzer",
    apiEndpoint: "/api/instagram_analyzer",
    isArtists: true,
    filters: null,
    columns: [
      "artwork",
      "artistName",
      // "inFollowersSparkline",
      "followers",
      "avg_change_followers",
    ],
    defaultFilters: {},
    bgFrom: colourMap["instagram"].primary,
    bgTo: colourMap["instagram"].to,
    facetMapping,
  },
  sc_scouts: {
    pageTitle: "SoundCloud Scoutings",
    apiEndpoint: "/api/charts/SoundCloudMyScoutings?useMatrix=1",
    isArtists: true,
    defaultSort: "last_scouted_at",
    defaultFilters: {
      countries: [],
      nameStartsWith: null,
    },
    filters: (currentValues, onModify, revertCount, isModified) => {
      return (
        <React.Fragment>
          <Searcher
            label="Artist Name"
            value={currentValues.nameStartsWith}
            className="filter-name"
            onChange={nameStartsWith => onModify({ nameStartsWith })}
          />
          <Tagger
            label="Country"
            key={revertCount + "country"}
            domain={countries}
            initialSelection={currentValues.countries || []}
            className="filterControl filter-countries"
            onChange={countries => onModify({ countries })}
          />
        </React.Fragment>
      );
    },
    columns: [
      "artwork",
      "artistName",
      "last_scouted_at",
      "allScouts",
      "total_scouts",
      "recent_scouts",
      // "followers",
      // "avg_change_followers"
    ],
    bgFrom: colourMap["soundcloud"].primary,
    bgTo: colourMap["soundcloud"].to,
  },
  tt_scouts: {
    pageTitle: "TikTok Scoutings",
    apiEndpoint: "/api/tiktok_scoutings",
    isArtists: false,
    filters: null,
    facetMapping: (function() {
      const total_play_thresholds = [100, 1000, 5000, 10000, 500000];
      const total_play_labels = makeLabelsFromThresholds(total_play_thresholds);
      const scouts_thresholds = [2, 3, 4, 5];
      const scouts_labels = ["1", "2", "3", "4+"];
      return {
        num_videos: {
          name: "Total Videos",
          func: l => valueToBucket(l, total_play_thresholds, total_play_labels),
          customSort: (a, b) =>
            total_play_labels.indexOf(a) - total_play_labels.indexOf(b),
        },
        num_unique_scouts: {
          name: "# Scouts",
          func: l => valueToBucket(l, scouts_thresholds, scouts_labels),
          customSort: (a, b) =>
            scouts_labels.indexOf(a) - scouts_labels.indexOf(b),
        },
        earliest_scout_video_days_ago: {
          name: "Earliest Scout Video",
          func: days =>
            days < 2
              ? "< 1 days ago"
              : days < 5
              ? "2 - 5 days ago"
              : days < 10
              ? "5 - 10 days ago"
              : days < 30
              ? "10 - 30 days ago"
              : days < 60
              ? "30 - 60 days ago"
              : "> 60 days ago",
        },
        scout_categories: {
          name: "Scout Categories",
          customStyler: {
            barColor: name => (name == "none" ? "#aaa" : categoryColour(name)),
          },
        },
      };
    })(),
    columns: [
      "artwork",
      "title",
      "author",
      "open_on_tiktok",
      "num_videos",
      // "first_seen",
      "top_video_likes",
      "avg_video_likes",
      "num_scout_videos",
      "num_unique_scouts",
      "earliest_scout_video_created",
      "first_scout",
      "last_scout_video_created",
      "last_scout",
    ],
    defaultFilters: {},
    bgFrom: colourMap["tiktok"].primary,
    bgTo: colourMap["tiktok"].to,
    headerBackgroundImage: colourMap.tiktok.backgroundImage,
  },
};

const Analyzer = ({
  initialType,
  includeSwitch = false,
  settingsLink,
  renderEmptyResults,
}) => {
  const [currentType, setCurrentType] = React.useState(
    initialType || "tt_scouts"
  );
  const type = types[currentType];
  const _switchMap = {
    sc: "spy",
    spy: "in",
    in: "sc_scouts",
    sc_scouts: "tt_scouts",
    tt_scouts: "sc",
  };
  return (
    <React.Fragment>
      {includeSwitch && (
        <div
          style={{
            position: "absolute",
            zIndex: 1000,
            width: 50,
            height: 50,
            backgroundColor: "red",
          }}
          onClick={() => setCurrentType(_switchMap[currentType])}
        >
          {currentType}
        </div>
      )}
      <ResultsPage
        pageId="sc_analyzer"
        pageTitle={type.pageTitle}
        filters={null}
        loading={false}
        loaded={true}
        settingsLink={settingsLink}
        headerStyle={{
          backgroundImage:
            type.headerBackgroundImage ||
            `linear-gradient(30deg, ${type.bgFrom}, ${type.bgTo})`,
        }}
      >
        <ChartExplorer
          key={currentType}
          apiEndpoint={type.apiEndpoint}
          defaultSort={type.defaultSort}
          defaultFilters={type.defaultFilters}
          renderFilters={type.filters}
          facetMapping={type.facetMapping}
          columnList={type.columns}
          isArtists={type.isArtists}
          renderEmptyResults={renderEmptyResults}
        />
      </ResultsPage>
    </React.Fragment>
  );
};

export default Analyzer;
