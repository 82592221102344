import React, { useEffect, useState } from "react";

import { getUsername } from "../middleware/auth";

import { actionCreators } from "../reducers/user";
import { connect } from "react-redux";
import styled from "styled-components";
import { Button, TextInput } from "../components/primitives/inputs";
import { Loading, Logo, Spinner } from "../components/primitives/things";

import { cognitoLogin } from "../middleware/auth";
import { HEADER_HEIGHT } from "../styles/styleConsts";
import { Auth0Context } from "./Auth0Context";

import loginLogo from "../images/dna-black.png";
import buttonLogo from "../images/btn-logo-sony.png";
const Styled = styled.div`
  margin: 30px auto;
  line-height: 40px;
  text-align: left;
  width: 200px;

  .welcomeMessage {
    line-height: 21px;
    font-size: 12px;
    margin: 10px 0;
    min-height: 60px;
  }
`;

const FullScreen = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  background-color: white;
`;

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: this.props.forUsername || "",
      password: "",
    };

    this._handleUsername = this._handleUsername.bind(this);
    this._handlePassword = this._handlePassword.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
  }

  _handleUsername(ev) {
    this.setState({ username: ev.target.value });
  }

  _handlePassword(ev) {
    this.setState({ password: ev.target.value });
  }

  _handleSubmit(ev) {
    ev.preventDefault();
    this.props.onLogin(this.state.username, this.state.password, new URLSearchParams(window.location.search).get('local') === '1');
  }

  render() {
    const { screenMessage } = this.props;
    return (
      <Styled>
        <form onSubmit={this._handleSubmit}>
          <div style={{ textAlign: "center" }}>
            <img
              src={loginLogo}
              style={{
                display: "inline-block",
                width: "100px",
                height: "100px",
              }}
            />
          </div>
          <div className="welcomeMessage">
            {screenMessage
              ? screenMessage
              : "Good " +
                (new Date().getHours() < 12
                  ? "morning"
                  : new Date().getHours() < 18
                  ? "afternoon"
                  : "evening") +
                "."}
          </div>
          <TextInput
            className="sel-loginUsername"
            autocomplete="username"
            type="text"
            placeholder="username"
            value={this.state.username}
            onChange={this._handleUsername}
          />
          <TextInput
            className="sel-loginPassword"
            autocomplete="current-password"
            type="password"
            placeholder="password"
            value={this.state.password}
            onChange={this._handlePassword}
          />
          <div style={{ textAlign: "right" }}>
            <Button type="submit">Login</Button>
          </div>
        </form>
      </Styled>
    );
  }
}

async function doCognitoLogin(
  user,
  pass,
  { loginAccessToken, loginUserPass, dispatchLoginError, onMustChangePassword }
) {
  try {
    const accessToken = await cognitoLogin(user, pass);
    loginAccessToken(accessToken, true);
  } catch (e) {
    if (e.action === "NEW_PASSWORD_REQUIRED" && e.cognitoUser) {
      console.log("Requiring a new password");
      onMustChangePassword(e.cognitoUser);
    } else if (typeof e === "string") {
      dispatchLoginError(e);
    } else {
      console.log("Attempting old login with ", user);
      loginUserPass(user, pass);
    }
  }
}

const connectToRedux = connect(
  (state) => ({
    currentUser: state.user.currentUser,
    loginInProgress: state.ui.loginInProgress,
    loginWithTokenInProgress: state.ui.loginWithTokenInProgress,
    loginPageMessage: state.ui.loginPageMessage,
  }),
  (dispatch) => ({
    loginUserPass: (user, pw) => dispatch(actionCreators.login(user, pw)),
    loginAccessToken: (accessToken, cognito=false) =>
      dispatch(actionCreators.loginToken(accessToken, cognito)),
    loginAuth0Token: (token) => dispatch(actionCreators.loginAuth0Token(token)),
    refreshUser: () => dispatch(actionCreators.refreshUser()),
    fetchUser: () => dispatch(actionCreators.fetchUser()),
    dispatchLoginError: (message) =>
      dispatch({ type: "loginFailedWithMessage", message }),
  })
);

class Component extends React.Component {
  state = {
    mustChangePasswordCognitoUser: null,
    loginMethodChosen: "cognito", // xxx hardcode until actual release. null,
  };

  constructor(props) {
    super(props);
    this.interval = null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextState.mustChangePasswordCognitoUser !==
      this.state.mustChangePasswordCognitoUser
    ) {
      return true;
    }
    if (nextState.loginMethodChosen !== this.state.loginMethodChosen) {
      return true;
    }
    if (nextProps.loginInProgress !== this.props.loginInProgress) {
      return true;
    }
    if (
      (nextProps.currentUser || {}).name !== (this.props.currentUser || {}).name
    ) {
      return true;
    }
    if (
      (nextProps.currentUser || {}).sessionHasExpired !==
      (this.props.currentUser || {}).sessionHasExpired
    ) {
      return true;
    }

    if (nextProps.loginPageMessage !== this.props.loginPageMessage) {
      return true;
    }

    console.log("Login component not updating");
    return false;
  }

  componentDidUpdate() {
    if (this.interval) {
      window.clearInterval(this.interval);
    }

    if (this.props.currentUser) {
      this.interval = window.setInterval(
        () => this.props.refreshUser(),
        10000 //30 * 60 * 1000
      );
    }
  }

  render() {
    const {
      currentUser,
      loginInProgress,
      loginPageMessage,
      loginUserPass,
      loginAccessToken,
      // loginAuth0Token,
      dispatchLoginError,
    } = this.props;

    const onMustChangePassword = (cognitoUser) => {
      this.setState({ mustChangePasswordCognitoUser: cognitoUser });
    };

    const onLogin = async (username, password) => {
      console.log("Logging in ", this.context);

      // await loginWithAuth0(loginAuth0Token);

      // if (local) {
      //   return loginAccessToken(username + ":" + password);
      // }

      doCognitoLogin(username, password, {
        loginAccessToken,
        loginUserPass,
        dispatchLoginError,
        onMustChangePassword,
      });
    };

    const loginMethodChosen = "cognito"; // hardcode until release this.state.loginMethodChosen;
    if (
      loginInProgress
      // !this.context ||
      // this.context.loading ||
      // loginMethodChosen === "auth0"
    ) {
      // note we show the loading screen when 'auth0' is chosen, because the acutal login is handled out of band,
      // and then this whole component will be remounted.
      return (
        <FullScreen>
          <Loading
            style={{ paddingTop: "20%", margin: "0 auto", textAlign: "center" }}
          >
            Loading...
          </Loading>
        </FullScreen>
      );
    }
    if (currentUser && !currentUser.sessionHasExpired) {
      return null;
    }

    if (loginMethodChosen === null) {
      return (
        <FullScreen>
          <div
            style={{
              textAlign: "center",
              margin: "20px auto",
              maxWidth: "500px",
            }}
          >
            <Logo
              style={{
                display: "inline-block",
                width: "82px",
                height: HEADER_HEIGHT + "px",
              }}
            />
            {loginPageMessage && (
              <p style={{ maxWidth: "500px" }}>{loginPageMessage}</p>
            )}
            <p>How do you want to login?</p>

            <p>
              <Button
                onClick={() => this.setState({ loginMethodChosen: "cognito" })}
              >
                Login with Whitelist username and password
              </Button>
            </p>

            <p>
              <Button
                onClick={() => {
                  this.setState({ loginMethodChosen: "auth0" });
                  this.context.loginWithRedirect();
                }}
              >
                Login with Sony Apollo Account
              </Button>
            </p>
          </div>
        </FullScreen>
      );
    }

    let screenMessage = loginPageMessage;
    let forUsername = getUsername();
    if (currentUser && currentUser.sessionHasExpired) {
      screenMessage =
        "You've been away a little too long, please enter your password again to continue.";
      forUsername = currentUser.username;
    }
    return (
      <FullScreen>
        {this.state.mustChangePasswordCognitoUser ? (
          <NewPasswordForm
            cognitoUser={this.state.mustChangePasswordCognitoUser}
            onSuccess={(newPass) => {
              onLogin(
                this.state.mustChangePasswordCognitoUser.username,
                newPass
              );
              this.setState({ mustChangePasswordCognitoUser: null });
            }}
          />
        ) : (
          <LoginForm
            onLogin={onLogin}
            screenMessage={screenMessage}
            forUsername={forUsername}
          />
        )}
      </FullScreen>
    );
  }
}

Component.contextType = Auth0Context;

function NewPasswordForm({ cognitoUser, onSuccess }) {
  const [newPass, setNewPass] = useState("");
  const [repeatPass, setRepeatPass] = useState("");

  const isValid = newPass.length >= 8;
  const isSame = isValid && repeatPass && newPass === repeatPass;

  function setNewPassword() {
    if (isSame) {
      cognitoUser.completeNewPasswordChallenge(newPass, null, {
        onSuccess: (session) => {
          console.log("New password succeeded!");
          onSuccess(newPass);
        },
        onFailure: (error) => {
          console.log("Failed to set password", error);
        },
      });
    }
  }

  return (
    <Styled>
      <form onSubmit={() => setNewPassword()}>
        <div style={{ textAlign: "center" }}>
          <Logo
            style={{
              display: "inline-block",
              width: "82px",
              height: HEADER_HEIGHT + "px",
            }}
          />
        </div>
        <div className="welcomeMessage">
          You must set a new password. It must be at least 8 characters long,
          peferably longer!{" "}
        </div>
        <TextInput
          type="text"
          placeholder="new password"
          value={newPass}
          onChange={(ev) => setNewPass(ev.target.value)}
        />
        <TextInput
          className="sel-loginPassword"
          type="test"
          placeholder="repeat password"
          value={repeatPass}
          onChange={(ev) => setRepeatPass(ev.target.value)}
        />
        <div style={{ textAlign: "right" }}>
          <Button disabled={!isSame} type="submit">
            Set Password
          </Button>
        </div>
      </form>
    </Styled>
  );
}

export const StoryComponent = Component;

// export const LoginOverlay = connectToRedux(Component);

import { useIsAuthenticated, useMsal } from "@azure/msal-react";

function getMsalAccountInfo(msalInstance) {

    const account = msalInstance.getAllAccounts()?.[0];
    if (!account) {
      console.log("No entra account logged in")
      return null;
    }
    if ((account.idTokenClaims?.exp || 0) < Date.now() / 1000) {
      console.log("idToken expired.")
      return null;
    }
    return account;

}

async function loginWithMsal(msalInstance) {
  try {
    const account = getMsalAccountInfo(msalInstance);
    if (!account) {
      console.log("No user logged in, redirecting to login");
      msalInstance.loginRedirect({ scopes: ["User.Read"] });
    } else {
      return account;
    }
  } catch (error) {
    console.error("Error during MSAL initialization:", error);
    throw new Error("Login failure");
  }
}

function MsEntra({
  currentUser,
  loginWithTokenInProgress,
  loginInProgress,
  loginPageMessage,
  loginAccessToken,
  // loginAuth0Token,
}) {
  // Instance is initialized in the root_module
  const { instance } = useMsal();
  const [checkingRedirect, setCheckingRedirect] = useState(false);

  console.log("MsEntra", {loginInProgress,loginWithTokenInProgress,checkingRedirect})

  useEffect(() => {
    // handle coming back from a redirected login (returns null if normal page load):
    setCheckingRedirect(true);
    instance.handleRedirectPromise().then(response => {
      if (response) {
        console.debug("MS Entre login successful:", response);
        loginAccessToken(response.idToken);
      }
      setCheckingRedirect(false);
    })
  }, [])

  async function doLogin() {
    const msalAccountInfo = await loginWithMsal(instance);
    if (msalAccountInfo) {
      loginAccessToken(msalAccountInfo.idToken);
    }
  }




  if (currentUser && !currentUser.sessionHasExpired) {
    return null;
  }


  return (
    <FullScreen>
      <Styled>
        <div style={{ textAlign: "center" }}>
          <img
            src={loginLogo}
            style={{
              display: "inline-block",
              width: "100px",
              height: "100px",
            }}
          />
        </div>
        {(loginWithTokenInProgress || loginInProgress || checkingRedirect) ? (
          <Spinner />
        ) : (
          <>
            <div className="welcomeMessage">
              {loginPageMessage
                ? loginPageMessage
                : "Good " +
                  (new Date().getHours() < 12
                    ? "morning"
                    : new Date().getHours() < 18
                    ? "afternoon"
                    : "evening") +
                  "."}
            </div>
            <div>
              <button
                onClick={doLogin}
                style={{
                  boxShadow: "3px 3px 5px rgba(0,0,0,0.4)",
                  borderRadius: "35px",
                  background:
                    "linear-gradient(0deg, rgba(32,32,32,1) 0%, rgba(64,64,64,1) 90%)",
                  borderWidth: 0,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "5px",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <img src={buttonLogo} style={{ height: "30px" }} />
                  <span style={{ color: "white" }}>
                    Login with your Sony Music Account
                  </span>
                </div>
              </button>
            </div>
          </>
        )}
      </Styled>
    </FullScreen>
  );

  return <Spinner />;
}

function Choice(props) {
  const [isLocal, setIsLocal] = useState(false);
  useEffect(() => {
    if (new URLSearchParams(window.location.search).get('local') === '1') {
      setIsLocal(true);
    } else {
      setIsLocal(false);
    }
  }, []);


  return isLocal ? <Component {...props} /> : <MsEntra {...props} />;
}

export const LoginOverlay = connectToRedux(Choice);
