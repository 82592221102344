import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import { applyMiddleware, compose, createStore } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { actionCreators } from "./reducers/main";
import api from "./middleware/api";

import App from "./containers/app";

import { configureStore as configureHash } from "./routing/hashredux";
import { rollbarMiddleware } from "./middleware/rollbar";
import { ProvideConnectedDispatch } from "./reducers/ReduxHooks";
import { artistCacheStatusMiddleware } from "./middleware/artist_cache_status_updater";

import { AmplitudeProvider } from "./containers/Amplitude";
import { UserProvider } from "./containers/UserContext";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

/**
 * In order to get the hot module reloading with redux working, we need to put a 'root module' around the
 * exported app module.  See the `hot(module)(App)` line at the bottom of the app.js
 */

let composeEnhancers = compose;
if (
  process.env.NODE_ENV !== "production" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
) {
  console.debug("Enableing redux devtools");
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
} else {
  console.debug("No devtools");
}

function configureStore(msalInstance) {
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("./reducers/main", () => {
      const nextRootReducer = require("./reducers/main").default;
      store.replaceReducer(nextRootReducer);
    });
  }

  // if (process.env.NODE_ENV !== 'production') {
  //   const {whyDidYouUpdate} = require('why-did-you-update')
  //   whyDidYouUpdate(React);
  // }

  const reducer = require("./reducers/main").default;

  const store = createStore(
    reducer,
    composeEnhancers(
      applyMiddleware(
        thunk,
        rollbarMiddleware(),
        api(msalInstance),
        artistCacheStatusMiddleware()
      )
    )
  );

  configureHash(store);

  window.__store = store;

  return store;
}


const msalInstance = new PublicClientApplication(msalConfig);
const store = configureStore(msalInstance);


const Root = () => {
  const [msalInitialised, setMsalInitialised] = useState(false);

  useEffect(() => {
    console.log({msalInitialised})
    if (!msalInitialised) {
      msalInstance.initialize().then(() => {
        init();
        setMsalInitialised(true)
      });
    }
  }, [msalInitialised])

  return !msalInitialised ? null : (<MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <ProvideConnectedDispatch>
        <AmplitudeProvider>
          <UserProvider>
            <App />
          </UserProvider>
        </AmplitudeProvider>
      </ProvideConnectedDispatch>
    </Provider>
  </MsalProvider>)
};
const init = () => store.dispatch(actionCreators.init());



export function authenticateAndRender(rootElement) {
  ReactDOM.render(<Root />, rootElement);
}
